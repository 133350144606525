.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.link-icon {
  font-size: 1.5em;
  margin-right: 8px;
  border-bottom: none;
}

.badge {
  text-transform: none;
  float: left;
  margin-right: 10px;
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #3498db;
  font-weight: 500;
  border-radius: .2rem;
  padding: 0em .5em 0em .5em;
}
.badge-success {
  background-color: #18bc9c;
}
.badge-danger {
  background-color: #e74c3c;
}
.badge-warning {
  background-color: #f39c12;
}
.badge-info {
  background-color: #3498db;
}

html, body {
  height: 100% !important;
}
body {
  padding: 0 !important;
}
#root {
  height: 100%;
  width: 100%;
  padding: 80px 32px 32px 32px;
}